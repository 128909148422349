export enum StatusEnum {
    OPEN = '1',
    IN_PROGRESS = '2',
    CLOSED = '3',
  }
  
  // Reverse mapping for display purposes
  export const StatusText = {
    [StatusEnum.OPEN]: 'Open',
    [StatusEnum.IN_PROGRESS]: 'In Progress',
    [StatusEnum.CLOSED]: 'Closed'
  };
  