<mat-card class="cardWithShadow theme-card">
  <mat-card-header>
    <mat-card-title class="m-b-0">Create Ticket</mat-card-title>
  </mat-card-header>
  <mat-card-content class="b-t-1">
    <form [formGroup]="ticketForm" (ngSubmit)="onSubmit()">
      <!-- Name -->
      <mat-label>Name</mat-label>
      <mat-form-field appearance="outline" class="w-100 m-b-16">
   
        <input matInput formControlName="name" />
        <mat-error *ngIf="ticketForm.get('name')?.invalid && ticketForm.get('name')?.touched">
          Name is required.
        </mat-error>
      </mat-form-field>

      <!-- Email -->
      <mat-label>Email</mat-label>
      <mat-form-field appearance="outline" class="w-100 m-b-16">
      
        <input matInput formControlName="email" />
        <mat-error *ngIf="ticketForm.get('email')?.invalid && ticketForm.get('email')?.touched">
          Please enter a valid email.
        </mat-error>
      </mat-form-field>

      <!-- Mobile Number -->
      <mat-label>Mobile Number</mat-label>
      <mat-form-field appearance="outline" class="w-100 m-b-16">
      
        <input matInput formControlName="phoneNumber" />
        <mat-error *ngIf="ticketForm.get('phoneNumber')?.invalid && ticketForm.get('phoneNumber')?.touched">
          Mobile Number is required.
        </mat-error>
      </mat-form-field>

      <!-- Session ID -->
      <mat-label>Session ID</mat-label>
      <mat-form-field appearance="outline" class="w-100 m-b-16">
     
        <input matInput formControlName="sessionId" />
        <mat-error *ngIf="ticketForm.get('sessionId')?.invalid && ticketForm.get('sessionId')?.touched">
          Session ID is required.
        </mat-error>
      </mat-form-field>

      <!-- Category -->
      <mat-label>Category</mat-label>
      <mat-form-field appearance="outline" class="w-100 m-b-16">
       
        <mat-select formControlName="category">
          <mat-option *ngFor="let option of categories" [value]="option.id">
            {{ option.name }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="ticketForm.get('category')?.invalid && ticketForm.get('category')?.touched">
          Category is required.
        </mat-error>
      </mat-form-field>

     

      <!-- Reference -->
      <mat-label>Reference</mat-label>
      <mat-form-field appearance="outline" class="w-100 m-b-16">
      
        <input matInput formControlName="reference" />
        <mat-error *ngIf="ticketForm.get('reference')?.invalid && ticketForm.get('reference')?.touched">
          Reference is required.
        </mat-error>
      </mat-form-field>

      <!-- Description -->
      <mat-label>Description</mat-label>
      <mat-form-field appearance="outline" class="w-100 m-b-16">
      
        <textarea matInput rows="5" formControlName="description"></textarea>
        <mat-error *ngIf="ticketForm.get('description')?.invalid && ticketForm.get('description')?.touched">
          Description is required.
        </mat-error>
      </mat-form-field>


       <!-- Language -->
       <mat-label>Language</mat-label>
       <mat-form-field appearance="outline" class="w-100 m-b-16">
        
        <mat-select formControlName="lang">
          <mat-option *ngFor="let lang of languages" [value]="lang.code">
            {{ lang.name }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="ticketForm.get('lang')?.invalid && ticketForm.get('lang')?.touched">
          Language is required.
        </mat-error>
      </mat-form-field>

      <!-- File Upload -->
      <mat-label class="mat-subtitle-2 f-w-600 d-block m-b-16">Appendex (File Upload)</mat-label>
      <input type="file" (change)="onFileUpload($event)" class="m-b-16" />

      <!-- Submit Button -->
      <div class="text-right">
        <button mat-flat-button color="primary" [disabled]="ticketForm.invalid" type="submit">
          Submit
        </button>
      </div>
    </form>
  </mat-card-content>
</mat-card>
