import { Component, OnInit, Inject, Optional, ViewChild } from '@angular/core';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MaterialModule } from 'src/app/material.module';
import { CommonModule } from '@angular/common';
import { TablerIconsModule } from 'angular-tabler-icons';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TicketElement } from '../global/globalticket/ticket';

export interface DeviceElement {
  deviceId: number;
  name: string;
  locationId: string;
  inkLevel: string;
  paperLevel: string;
  error: string;
  status: string;
}

const devices: DeviceElement[] = [
  { deviceId: 1, name: 'Device A', locationId: 'L001', inkLevel: '80%', paperLevel: '50%', error: 'None', status: 'idle' },
  { deviceId: 2, name: 'Device B', locationId: 'L002', inkLevel: '50%', paperLevel: '30%', error: 'Paper Jam', status: 'use' },
  // Add more devices here
];

@Component({
  selector: 'app-device-table',
  templateUrl: './device.component.html',
  standalone: true,
  imports: [MaterialModule, CommonModule, TablerIconsModule],
})
export class DeviceTableComponent implements OnInit {
  @ViewChild(MatTable, { static: true }) table: MatTable<any> = Object.create(null);
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator = Object.create(null);
  
searchText: any;
totalCount = -1;
Closed = -1;
Inprogress = -1;
Open = -1;
  displayedColumns: string[] = ['deviceId', 'name', 'locationId', 'inkLevel', 'paperLevel', 'error', 'status', 'action'];
  dataSource = new MatTableDataSource(devices);

  constructor(public dialog: MatDialog) {}

  ngOnInit(): void {

    this.dataSource.paginator = this.paginator;
    this.totalCount = this.dataSource.data.length;
    this.Open = this.btnCategoryClick('Open');
    this.Closed = this.btnCategoryClick('Closed');
    this.Inprogress = this.btnCategoryClick('InProgress');
    this.dataSource = new MatTableDataSource(devices);
  }

  applyFilter(filterValue: string): void {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  btnCategoryClick(val: string): number {
    this.dataSource.filter = val.trim().toLowerCase();
    return this.dataSource.filteredData.length;
  }

  openDialog(action: string, obj: any): void {
    obj.action = action;
    if (action === 'Restart') {
      const dialogRef = this.dialog.open(RestartConfirmationDialogComponent, {
        data: obj,
        width: '400px',  // Custom width
        height: 'auto',  // Adjust height automatically
      });
  
      dialogRef.afterClosed().subscribe((result) => {
        if (result.event === 'Confirmed') {
          this.restartDevice(result.data);
        }
      });
    } else {
      const dialogRef = this.dialog.open(AppTicketDialogContentComponent, {
        data: obj,
        width: '700px',  // You can also specify different sizes for different dialogs
        height: 'auto',  // Adjust height automatically
      });
  
      dialogRef.afterClosed().subscribe((result) => {
        if (result.event === 'Update') {
          this.updateDevice(result.data);
        } else if (result.event === 'Delete') {
          this.deleteDevice(result.data);
        }
      });
    }
  }
  

  restartDevice(device: DeviceElement): void {
    console.log('Device restarted:', device);
  }

  updateDevice(device: DeviceElement): void {
    console.log('Device updated:', device);
  }

  deleteDevice(device: DeviceElement): void {
    this.dataSource.data = this.dataSource.data.filter((d) => d.deviceId !== device.deviceId);
    this.table.renderRows();
  }
}

@Component({
  // tslint:disable-next-line - Disables all
  selector: 'app-dialog-content',
  templateUrl: 'device-dialog-content.html',
  standalone: true,
  imports: [
    MaterialModule,
    CommonModule,
    TablerIconsModule,
    FormsModule,
    ReactiveFormsModule,
  ],
})
// tslint:disable-next-line - Disables all
export class AppTicketDialogContentComponent {
  action: string;
  // tslint:disable-next-line - Disables all
  local_data: any;

  constructor(
    public dialogRef: MatDialogRef<AppTicketDialogContentComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: TicketElement
  ) {
    this.local_data = { ...data };
    this.action = this.local_data.action;
  }

  doAction(): void {
    this.dialogRef.close({ event: this.action, data: this.local_data });
  }

  closeDialog(): void {
    this.dialogRef.close({ event: 'Cancel' });
  }
}


@Component({
  selector: 'app-restart-confirmation-dialog',
  templateUrl: './device-restart-dialog-content.html',
  standalone: true,
  imports: [MaterialModule, CommonModule, TablerIconsModule, FormsModule, ReactiveFormsModule],
})
export class RestartConfirmationDialogComponent {
  killPassword: string = '';
  correctPassword = '1234'; // Replace with actual password logic
  isPasswordCorrect = false;
  errorMessage: string = '';

  constructor(
    public dialogRef: MatDialogRef<RestartConfirmationDialogComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: DeviceElement
  ) {}

  checkPassword(): void {
    if (this.killPassword === this.correctPassword) {
      this.isPasswordCorrect = true;
      this.errorMessage = '';
    } else {
      this.errorMessage = 'The password is not correct';
      this.isPasswordCorrect = false;
    }
  }

  confirmRestart(): void {
    if (this.isPasswordCorrect) {
      this.dialogRef.close({ event: 'Confirmed', data: this.data });
    }
  }

  closeDialog(): void {
    this.dialogRef.close({ event: 'Cancel' });
  }
}
