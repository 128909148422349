import { Pipe, PipeTransform } from '@angular/core';
import { StatusEnum, StatusText } from './status.enum';

@Pipe({
  name: 'statusText',
  standalone: true, // This makes the pipe standalone
})
export class StatusTextPipe implements PipeTransform {
  transform(value: StatusEnum): string {
    return StatusText[value] || 'Unknown';
  }
}
