<div class="row">
  <!-- First Column -->
  <div class="col-lg-6 col-md-12">
    <!-- Ticket Details Card -->
    <mat-card *ngIf="ticket">
      <mat-card-header>
        <mat-card-title>Ticket Details</mat-card-title>
        <button mat-icon-button (click)="toggleEdit()">
          <mat-icon>edit</mat-icon>
        </button>
      </mat-card-header>
      <mat-card-content>
        <ul>
          <li><strong>Ticket ID:</strong> {{ ticket?.ticketUserId }}</li>
          <li><strong>Session ID:</strong> {{ ticket?.sessionId }}</li>
          <li><strong>Name:</strong> {{ ticket?.name }}</li>
          <li><strong>Email:</strong> {{ ticket?.email }}</li>
          <li><strong>Phone Number:</strong> {{ ticket?.phoneNumber }}</li>
          <li><strong>Category:</strong> {{ getCategoryText(ticket?.category) }}</li>
          <li><strong>Status:</strong> {{ getStatusText(ticket?.status) }}</li>
          <li><strong>Source:</strong> {{ getSourceText(ticket?.source) }}</li>
          <li><strong>Criticality:</strong> {{ getCriticalityText(ticket?.criticality) }}</li>
          <li><strong>Description:</strong> {{ ticket?.description }}</li>
          <li><strong>Created Date:</strong> {{ ticket?.created_at | date: 'dd.MM.yyyy HH:mm' }}</li>
          <li><strong>Last Change Date:</strong> {{ ticket?.lastModifiedDate | date: 'dd.MM.yyyy HH:mm' }}</li>
        </ul>
      </mat-card-content>
    </mat-card>

    <!-- Edit Ticket Details Card -->
    <mat-card *ngIf="isEditing">
      <mat-card-header>
        <mat-card-title>Edit Ticket Details</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <ul>
          <li>
            <strong>Category:</strong>
            <mat-select [(ngModel)]="ticket.category">
              <mat-option *ngFor="let category of categories" [value]="category.id">
                {{ category.name }}
              </mat-option>
            </mat-select>
          </li>
          <li>
            <strong>Status:</strong>
            <mat-select [(ngModel)]="ticket.status">
              <mat-option *ngFor="let status of statuses" [value]="status.id">
                {{ status.name }}
              </mat-option>
            </mat-select>
          </li>
          <li>
            <strong>Source:</strong>
            <mat-select [(ngModel)]="ticket.source">
              <mat-option *ngFor="let source of sources" [value]="source.id">
                {{ source.name }}
              </mat-option>
            </mat-select>
          </li>
          <li>
            <strong>Criticality:</strong>
            <mat-select [(ngModel)]="ticket.criticality">
              <mat-option *ngFor="let level of criticalities" [value]="level.id">
                {{ level.name }}
              </mat-option>
            </mat-select>
          </li>
        </ul>
        <div class="text-center">
          <button mat-raised-button color="primary" (click)="saveChanges()">Save</button>
          <button mat-raised-button color="warn" (click)="toggleEdit()">Cancel</button>
        </div>
      </mat-card-content>
    </mat-card>

    <!-- Add Note Card -->
    <mat-card>
      <mat-card-header>
        <mat-card-title>Add Note</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <textarea
          matInput
          placeholder="Enter your note here"
          [(ngModel)]="newNote"
          rows="4"
          class="w-100"
        ></textarea>
      </mat-card-content>
      <mat-card-actions>
        <button mat-raised-button color="primary" (click)="createNote()" [disabled]="!newNote.trim()">
          Add Note
        </button>
      </mat-card-actions>
    </mat-card>

    <!-- Notes Display Card -->
    <mat-card *ngIf="loadingNotes">
      <mat-card-content>Loading notes...</mat-card-content>
    </mat-card>
    <mat-card *ngIf="!loadingNotes && notes.length === 0">
      <mat-card-content>No notes available for this ticket.</mat-card-content>
    </mat-card>
    <div *ngIf="!loadingNotes && notes.length > 0">
      <mat-card *ngFor="let note of notes" class="mb-3">
        <mat-card-header>
          <mat-card-title>{{ note.action }}</mat-card-title>
          <mat-card-subtitle>
            Created by: <strong>{{ note.created_by || 'Unknown' }}</strong> | 
            {{ note.created_at | date: 'dd.MM.yyyy HH:mm' }}
          </mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
          <p>{{ note.note }}</p>
        </mat-card-content>
      </mat-card>
    </div>
    
  </div>

  <!-- Second Column -->
  <div class="col-lg-6 col-md-12">
    <!-- File Details Card -->
    <mat-card *ngIf="fileMetadata">
      <mat-card-header>
        <mat-card-title>File Details</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <ul>
          <li><strong>Original Name:</strong> {{ fileMetadata.originalName }}</li>
          <li><strong>Size:</strong> {{ fileMetadata.size }} bytes</li>
          <li><strong>Type:</strong> {{ fileMetadata.mimeType }}</li>
          <li>
            <strong>Download:</strong>
            <a [href]="fileMetadata.fileUrl" target="_blank">Download File</a>
          </li>
        </ul>
        <div class="text-center">
          <button mat-raised-button color="primary" (click)="openFile()">
            <mat-icon>open_in_new</mat-icon> Open File
          </button>
        </div>
      </mat-card-content>
    </mat-card>

    <div *ngIf="isSessionAvailable; else noSession">
      <!-- Payment Details Card -->
      <mat-card>
        <mat-card-header>
          <mat-card-title>Payment Details</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <ul>
            <li><strong>Total Paid Price:</strong> €{{ sessionData.data.total_paid_price }}</li>
            <li><strong>Total Paid Pages:</strong> {{ sessionData.data.total_paid_filepages }}</li>
            <li><strong>Total Paid Copies:</strong> {{ sessionData.data.total_paid_copies }}</li>
            <li><strong>Total Printed Pages:</strong> {{ sessionData.data.total_printedpages }}</li>
          </ul>
          <div class="text-center">
            <mat-label>Refund Amount</mat-label>
            <mat-form-field appearance="outline">
              <input matInput type="number" placeholder="Enter refund amount" />
            </mat-form-field>
            <button mat-raised-button color="warn">Refund</button>
          </div>
        </mat-card-content>
      </mat-card>
    
      <!-- File Details Card -->
      <mat-card>
        <mat-card-header>
          <mat-card-title>File Details</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <div *ngFor="let file of sessionData.filepages">
            <mat-card>
              <mat-card-content>
                <ul>
                  <li><strong>File Name:</strong> {{ file.filename }}</li>
                  <li><strong>Price:</strong> €{{ file.price }}</li>
                  <li><strong>Copies:</strong> {{ file.copies }}</li>
                  <li><strong>Paid At:</strong> {{ file.paid_at }}</li>
                  <li><strong>File Location:</strong> {{ file.file_location }}</li>
                  <li><strong>Print Paper Type:</strong> {{ file.print_paper_type }}</li>
                </ul>
              </mat-card-content>
            </mat-card>
          </div>
        </mat-card-content>
      </mat-card>
    
      <!-- Printed Pages Card -->
      <mat-card>
        <mat-card-header>
          <mat-card-title>Printed Pages</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <div *ngFor="let printed of sessionData.printedpages">
            <mat-card>
              <mat-card-content>
                <ul>
                  <li><strong>Printer ID:</strong> {{ printed.printer_id }}</li>
                  <li><strong>Panel ID:</strong> {{ printed.panel_id }}</li>
                  <li><strong>Print Status:</strong> {{ printed.is_printed ? 'Printed' : 'Failed' }}</li>
                  <li><strong>Printed At:</strong> {{ printed.printed_at }}</li>
                </ul>
                <div class="text-center">
                  <button mat-raised-button color="primary">
                    <mat-icon>print</mat-icon> Reprint
                  </button>
                </div>
              </mat-card-content>
            </mat-card>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
    
    <ng-template #noSession>
      <mat-card>
        <mat-card-header>
          <mat-card-title>No Session Data Available</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <p>No data available for the provided session ID.</p>
        </mat-card-content>
      </mat-card>
    </ng-template>
    
  </div>
</div>
