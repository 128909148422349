export const environment = {
    production: true,
    // supposing you have a backend where to send some requests
    backendUrl: "http://127.0.0.1:8000",
    // The firebase config you retrieved from the console.
    // Note that this is NOT sensitive information
    firebaseConfig: {
        apiKey: "AIzaSyBj5SGht-xX66u2p9uG6RPHMgAW6n1GOQM",
        authDomain: "signprint-df737.firebaseapp.com",
        projectId: "signprint-df737",
        storageBucket: "signprint-df737.firebasestorage.app",
        messagingSenderId: "42158378341",
        appId: "1:42158378341:web:e8b2b9f5fed5b2b9f8e58c",
        measurementId: "G-EYRRB8V8J6"
    },
    environmentWeb : {
        production: false,
        baseUrl: 'https://dashapi-dev.signprint.ch',
        secretKey: 'Nf7%tZ!eG$yLq0Xz&2rW8@pT3Kq^Fb9s',
        webshopUrl: 'https://dev.signprint.ch/api/dashboard',
        webshopsecretKey: 'software<3kekse'
      }
  };