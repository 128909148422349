<!-- listing and total no.of counts -->
<div class="row">
  <div class="col-sm-6 col-lg-3">
    <mat-card class="shadow-none">
      <div class="p-30 bg-light-primary text-center cursor-pointer rounded" (click)="btnCategoryClick('')">
        <h3 class="f-s-24 text-primary f-w-600 m-0 m-b-4">{{ totalCount }}</h3>
        <h6 class="text-primary f-w-600 m-0 f-s-16">Total Device</h6>
      </div>
    </mat-card>
  </div>
  <div class="col-sm-6 col-lg-3">
    <mat-card class="shadow-none">
      <div class="p-30 bg-light-warning text-center cursor-pointer rounded" (click)="btnCategoryClick('InProgress')">
        <h3 class="f-s-24 text-warning m-0 m-b-4">{{ Inprogress }}</h3>
        <h6 class="text-warning f-w-600 m-0 f-s-16">In Use</h6>
      </div>
    </mat-card>
  </div>
  <div class="col-sm-6 col-lg-3">
    <mat-card class="shadow-none">
      <div class="p-30 bg-light-success text-center cursor-pointer rounded" (click)="btnCategoryClick('Open')">
        <h3 class="f-s-24 text-success m-0 m-b-4">{{ Open }}</h3>
        <h6 class="text-success f-w-600 m-0 f-s-16">Idle</h6>
      </div>
    </mat-card>
  </div>
  <div class="col-sm-6 col-lg-3">
    <mat-card class="shadow-none">
      <div class="p-30 bg-light-error text-center cursor-pointer rounded" (click)="btnCategoryClick('Closed')">
        <h3 class="f-s-24 text-error m-0 m-b-4">{{ Closed }}</h3>
        <h6 class="text-error f-w-600 m-0 f-s-16">Any Error</h6>
      </div>
    </mat-card>
  </div>
</div>
<!-- add and search tickets -->
<mat-card class="cardWithShadow">
  <mat-card-content>
    <div class="row justify-content-between">
      <div class="col-sm-4">
        <mat-form-field appearance="outline" class="hide-hint">
          <input matInput (keyup)="applyFilter($any($event.target).value)" placeholder="Search Device" />
          <mat-icon matSuffix>
            <i-tabler name="search" class="icon-20"></i-tabler>
          </mat-icon>
        </mat-form-field>
      </div>
      <div class="col-sm-4 d-flex justify-content-end align-items-center">
        <button mat-flat-button (click)="openDialog('Add', {})" color="primary">
          Add Device
        </button>
      </div>
    </div>
  </mat-card-content>
</mat-card>
<!-- table -->
<mat-card class="cardWithShadow">
  <mat-card-content>
    <div class="table-responsive">
      <table mat-table [dataSource]="dataSource" class="w-100 f-w-500 no-wrap">

        <!-- Device ID Column -->
        <ng-container matColumnDef="deviceId">
          <th mat-header-cell *matHeaderCellDef class="f-s-16 f-w-600">Device Id</th>
          <td mat-cell *matCellDef="let element">{{ element.deviceId }}</td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef class="f-s-16 f-w-600">Name</th>
          <td mat-cell *matCellDef="let element">{{ element.name }}</td>
        </ng-container>

        <!-- Location Id Column -->
        <ng-container matColumnDef="locationId">
          <th mat-header-cell *matHeaderCellDef class="f-s-16 f-w-600">Location Id</th>
          <td mat-cell *matCellDef="let element">{{ element.locationId }}</td>
        </ng-container>

        <!-- Ink Level Column -->
        <ng-container matColumnDef="inkLevel">
          <th mat-header-cell *matHeaderCellDef class="f-s-16 f-w-600">Ink Level</th>
          <td mat-cell *matCellDef="let element">{{ element.inkLevel }}</td>
        </ng-container>

        <!-- Paper Level Column -->
        <ng-container matColumnDef="paperLevel">
          <th mat-header-cell *matHeaderCellDef class="f-s-16 f-w-600">Paper Level</th>
          <td mat-cell *matCellDef="let element">{{ element.paperLevel }}</td>
        </ng-container>

        <!-- Error Column -->
        <ng-container matColumnDef="error">
          <th mat-header-cell *matHeaderCellDef class="f-s-16 f-w-600">Error</th>
          <td mat-cell *matCellDef="let element">{{ element.error }}</td>
        </ng-container>

        <!-- Status Column -->
        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef class="f-s-16 f-w-600">Status</th>
          <td mat-cell *matCellDef="let element">
            <span *ngIf="element.status === 'idle'" class="bg-light-success mat-body-2 f-w-500 p-x-8 p-y-4 f-s-12 rounded-pill">Idle</span>
            <span *ngIf="element.status === 'use'" class="bg-light-warning mat-body-2 f-w-500 p-x-8 p-y-4 f-s-12 rounded-pill">In Use</span>
          </td>
        </ng-container>

        <!-- Actions Column -->
        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef class="f-s-16 f-w-600">Actions</th>
          <td mat-cell *matCellDef="let element" class="action-link">
            <a (click)="openDialog('Restart', element)" class="m-r-10 cursor-pointer"><i-tabler name="refresh" class="icon-18"></i-tabler></a>
            <a (click)="openDialog('Update', element)" class="m-r-10 cursor-pointer"><i-tabler name="edit" class="icon-18"></i-tabler></a>
            <a (click)="openDialog('Delete', element)" class="m-r-10 cursor-pointer"><i-tabler name="trash" class="icon-18"></i-tabler></a>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </div>

    <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
  </mat-card-content>
</mat-card>

