import { Component } from '@angular/core';
import { TicketmainComponent } from '../../tickets/ticketmain/ticketmain.component';  
import { CommonModule } from '@angular/common'; 
@Component({
  selector: 'app-create-ticket',
  standalone: true,
  imports: [TicketmainComponent,CommonModule], 
  templateUrl: './create-ticket.component.html',
  styleUrl: './create-ticket.component.scss'
})
export class CreateTicketComponent {


}





