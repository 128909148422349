<h2 mat-dialog-title>Restart Confirmation</h2>

<div mat-dialog-content>
  <p>Do you really think you need to restart?</p>

  <!-- Centered Image with a nice size -->
  <div class="centered-image">
    <img src="../../../assets/images/dilalog/restart.svg" alt="Restart Image" />
  </div>

  <!-- Input Field for Kill Password -->
  <mat-form-field class="full-width-input">
    <mat-label>Enter Kill Password</mat-label>
    <input matInput [(ngModel)]="killPassword" (input)="checkPassword()" type="password" />
  </mat-form-field>

  <!-- Error message if password is incorrect -->
  <p *ngIf="errorMessage" class="error-message">{{ errorMessage }}</p>
</div>

<div mat-dialog-actions align="end">
  <button mat-button (click)="closeDialog()">Cancel</button>
  <button mat-button [disabled]="!isPasswordCorrect" (click)="confirmRestart()">Yes</button>
</div>

<!-- Add your CSS classes -->
<style>
  .centered-image {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0;
  }

  .centered-image img {
    max-width: 100%;
    height: auto;
    width: 150px; /* Set a fixed width or adjust as needed */
  }

  .full-width-input {
    width: 100%;
  }

  .error-message {
    color: red;
    font-size: 0.9em;
  }
</style>
