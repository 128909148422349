import { Injectable, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class WebshopserviceService   {


 
  private baseUrl =  environment.environmentWeb.webshopUrl; 
  private apiKey =  environment.environmentWeb.webshopsecretKey;

  constructor(private http: HttpClient) {}

  /**
   * Fetch session data by session ID.
   * 
   * @param sessionId - The session ID to query
   * @param onlyPaidPages - Whether to include only paid pages
   * @returns Observable with session data
   */
  getSessionData(sessionId: string, onlyPaidPages: boolean): Observable<any> {
    const url = `${this.baseUrl}/session_data`;
    const headers = new HttpHeaders({
      'accept': 'application/json',
      'api-key': this.apiKey
    });

    const params = {
      session_id: sessionId,
      only_paid_pages: onlyPaidPages.toString()
    };

    return this.http.get(url, { headers, params });
  }
}