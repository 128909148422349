import { NavItem } from './nav-item/nav-item';

// Function to check user type
function isUserType(type: number): boolean {
  const user = localStorage.getItem('user');
  if (user) {
    const userData = JSON.parse(user);
    return userData.role == type;
  }
  return false;
}


export const navItems: NavItem[] = [
  {
    navCap: 'Dashboard',
  },
  {
    displayName: 'Dashboard',
    iconName: 'layout-dashboard',
    route: '',
  },
  {
    displayName: 'Orders',
    iconName: 'arrows-sort',
    route: '/orders/order-list',
  },
  {
    displayName: 'Tickets',
    iconName: 'ticket',
    route: 'dashboards/tickets',
  },

  {
    displayName: 'Device Health',
    iconName: 'stethoscope',
    route: 'dashboards/device',
  },



  {
    displayName: 'Access',
    iconName: 'key',
    route: 'dashboards/access',
  },
  
  {
    navCap: 'Settings',
  },
  ...(isUserType(1) // Show Accounts only for user type 1
  ? [
      {
        displayName: 'Accounts',
        iconName: 'box-multiple',
        route: '',
        children: [
          {
            displayName: 'Manage Accounts',
            iconName: 'point',
            route: '/settings/account-manage',
          },
          // Add other child routes if needed
        ],
      },
    ]
  : []),


 /* {
    displayName: 'Accounts',
    iconName: 'box-multiple',
    route: '',
    children: [

      {
        displayName: 'Manage Accounts',
        iconName: 'point',
        route: '/settings/account-manage',
      },
      /*{
        displayName: 'My Account',
        iconName: 'point',
        route: '/settings/account-setting',
      },
     
      
      {
        displayName: 'Login',
        iconName: 'lock',
        route: '/authentication/login',
      },
      {
        displayName: 'Register',
        iconName: 'home',
        route: '/authentication/register',
      },
      

     
    ],
  },*/
  
];
