import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MaterialModule } from 'src/app/material.module';
import { HttpClient } from '@angular/common/http';
import { TicketService } from 'src/app/services/ticket.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-ticketmain',
  standalone: true,
  imports: [MaterialModule, FormsModule, ReactiveFormsModule, CommonModule],
  templateUrl: './ticketmain.component.html',
  styleUrls: ['./ticketmain.component.scss']
})
export class TicketmainComponent implements OnInit {

  stepForms: FormGroup[] = [];
  categoryOptions: Array<{ id: number; name: string }> = [];
  selectedFile: File | null = null;
  ticketForm!: FormGroup; 

  categories: Array<{ id: any; name: string }> = []; // Adjust as per API
  categoryMap: any;

  languages = [
    { code: 'en', name: 'English' },
    { code: 'de', name: 'Deutsch' },
    { code: 'it', name: 'Italian' },
    { code: 'fr', name: 'French' },
  ];

  constructor(private fb: FormBuilder, private ticketService: TicketService,private snackBar: MatSnackBar) {}

  ngOnInit(): void {
    this.ticketForm = this.fb.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      sessionId: [''],
      category: ['', Validators.required],
      reference: ['', Validators.required],
      description: ['', Validators.required],
      phoneNumber: ['', Validators.required],
      lang: [''],
    });


    this.loadCategoryOptions();
  }


 
  private async loadCategoryOptions(): Promise<void> {
    try {
       // Load categories
       await this.ticketService.loadCategories();
       this.categoryMap = this.ticketService.categoryMap;
   
       // Map categories for dropdown
       this.categories = Object.keys(this.categoryMap).map((id) => ({
         id: id,
         name: this.ticketService.getCategoryText(Number(id)),
       }));

       console.log(this.categories);
    } catch (error) {
      console.error('Error loading categories:', error);
      this.snackBar.open('Failed to load categories. Please try again later.', 'Close', {
        duration: 3000,
      });
    }
  }

  onFileUpload(event: any): void {
    const file = event.target.files[0];
    if (file) {
      this.selectedFile = file;
    }
  }

  async onSubmit(): Promise<void> {
    if (this.ticketForm.invalid) {
      this.snackBar.open('Please fill in all required fields.', 'Close', { duration: 3000 });
      return;
    }

    

    const formData = this.ticketForm.value;

    try {
      // Step 1: Upload file
     var fileResponse;
      if (this.selectedFile) {
        fileResponse = await this.ticketService.uploadFile(this.selectedFile);
      }

      // Step 2: Prepare ticket data
    const ticketData = {
      name: formData.name,
      email: formData.email,
      phoneNumber: formData.phoneNumber, // Include phoneNumber in ticket data
      sessionId: formData.sessionId,
      category: formData.category,
      reference: formData.reference,
      description: formData.description,
      lang: formData.lang, // Include lang in ticket data
      fileId: fileResponse?.fileId, // Optional fileId if a file was uploaded
    };
      // Step 2: Prepare ticket data
      /* const ticketData = {
        ...formData,
        fileId: fileResponse?.fileId,
      }; */

      // Step 3: Create ticket
      const response = await this.ticketService.createTicket(ticketData);

      // Success
      this.snackBar.open(`Ticket created successfully! ID: ${response.ticketId}`, 'Close', { duration: 3000 });
       // Reset the form and clear touched states
    this.ticketForm.reset();
    this.ticketForm.markAsPristine();
    this.ticketForm.markAsUntouched();
      this.selectedFile = null;
    } catch (error) {
      console.log(error);
      // Handle errors during file upload or ticket creation
      this.snackBar.open('Error creating ticket. Please try again.', 'Close', { duration: 3000 });
    }
  }

  
}
