import { bootstrapApplication } from '@angular/platform-browser';
import { appConfig } from './app/app.config';
import { AppComponent } from './app/app.component';
import { enableProdMode } from '@angular/core';
import { environment } from './environments/environment';
import { initializeApp } from 'firebase/app'; // Import Firebase SDK initialization function

if (environment.production) {
  enableProdMode();
}

// Initialize Firebase
const firebaseApp = initializeApp(environment.firebaseConfig);  // Firebase configuration from your environment


bootstrapApplication(AppComponent, appConfig).catch((err) =>
  console.error(err)
);
