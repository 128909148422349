import { Component, OnInit, Inject, Optional, ViewChild } from '@angular/core';
import { MatTableDataSource, MatTable } from '@angular/material/table';

import { MatPaginator } from '@angular/material/paginator';
import { MaterialModule } from 'src/app/material.module';
import { CommonModule } from '@angular/common';
import { TablerIconsModule } from 'angular-tabler-icons';


import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

export interface UserElement {
  userId: number;
  name: string;
  accessId: number;
  status: string;
  lastLogin: string;
}

export interface DeviceElement {
  deviceId: number;
  name: string;
}

const users: UserElement[] = [
  { userId: 1, name: 'John Doe', accessId: 1, status: 'granted', lastLogin: '2023-09-01' },
  { userId: 2, name: 'Jane Smith', accessId: 2, status: 'no-access', lastLogin: '' },
  { userId: 3, name: 'Alice Johnson', accessId: 1, status: 'granted', lastLogin: '2023-09-05' },
  { userId: 4, name: 'Robert Brown', accessId: 2, status: 'no-access', lastLogin: '' },
  { userId: 5, name: 'Emily Davis', accessId: 3, status: 'granted', lastLogin: '2023-08-30' },
  { userId: 6, name: 'Michael Wilson', accessId: 1, status: 'granted', lastLogin: '2023-09-12' },
  { userId: 7, name: 'Sophia Garcia', accessId: 3, status: 'no-access', lastLogin: '' },
  { userId: 8, name: 'James Martinez', accessId: 2, status: 'granted', lastLogin: '2023-09-07' },
  { userId: 9, name: 'Olivia White', accessId: 1, status: 'no-access', lastLogin: '' },
  { userId: 10, name: 'William Hernandez', accessId: 4, status: 'granted', lastLogin: '2023-09-10' },
  { userId: 11, name: 'Isabella Moore', accessId: 4, status: 'no-access', lastLogin: '' },
  { userId: 12, name: 'Lucas Taylor', accessId: 3, status: 'granted', lastLogin: '2023-09-08' },
  { userId: 13, name: 'Mia Anderson', accessId: 2, status: 'granted', lastLogin: '2023-09-03' },
  { userId: 14, name: 'Benjamin Thomas', accessId: 1, status: 'no-access', lastLogin: '' },
  { userId: 15, name: 'Charlotte Lee', accessId: 3, status: 'granted', lastLogin: '2023-09-06' },
  { userId: 16, name: 'Liam Walker', accessId: 4, status: 'no-access', lastLogin: '' },
  { userId: 17, name: 'Amelia Hall', accessId: 2, status: 'granted', lastLogin: '2023-09-04' },
  { userId: 18, name: 'Mason Young', accessId: 1, status: 'no-access', lastLogin: '' },
  { userId: 19, name: 'Harper King', accessId: 4, status: 'granted', lastLogin: '2023-09-09' },
  { userId: 20, name: 'Ethan Wright', accessId: 2, status: 'no-access', lastLogin: '' }
];


const devices: DeviceElement[] = [
  { deviceId: 1, name: 'Device A' },
  { deviceId: 2, name: 'Device B' },
  // Add more devices here
];

@Component({
  selector: 'app-access-table',
  templateUrl: './access.component.html',
  standalone: true,
  imports: [MaterialModule, CommonModule, TablerIconsModule,FormsModule,ReactiveFormsModule]
})
export class AccessTableComponent implements OnInit {
  @ViewChild(MatTable, { static: true }) table: MatTable<any> = Object.create(null);
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator = Object.create(null);
  
  displayedColumns: string[] = ['userId', 'name', 'accessId', 'status', 'lastLogin', 'actions'];
  dataSource = new MatTableDataSource(users);

  devices = devices; // Available devices
  selectedDeviceId: number = this.devices[0].deviceId;
  selectedStatus: string = 'granted';
  searchName: string = '';

  ngOnInit(): void {
    this.dataSource.paginator = this.paginator;
    this.applyFilters(); 
  }

  // Filter by device
  applyDeviceFilter(deviceId: number): void {
    this.selectedDeviceId = deviceId;
    this.applyFilters();
  }

  // Filter by status
  applyStatusFilter(status: string): void {
    this.selectedStatus = status;
    this.applyFilters();
  }

  // Apply both filters
  applyFilters(): void {
    this.dataSource.data = users.filter((user) => {
      const deviceMatch = this.selectedDeviceId ? user.accessId === this.selectedDeviceId : true;
      const statusMatch = this.selectedStatus ? user.status === this.selectedStatus : true;
      const nameMatch = this.searchName ? user.name.toLowerCase().includes(this.searchName.toLowerCase()) : true;
      return deviceMatch && statusMatch && nameMatch;
    });
  }

  // Grant access action
  grantAccess(user: UserElement): void {
    user.status = 'granted';
    this.applyFilters();
  }

  // Revoke access action
  revokeAccess(user: UserElement): void {
    user.status = 'no-access';
    this.applyFilters();
  }
}
