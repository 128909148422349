@if(mobileQuery.matches){
<div class="horizontal-sidebar p-y-16 b-b-1">
  <div class="container">
    <div>
      <div class="horizontal-navbar hstack align-items-center">
        @for(item of navItems; track item){
          <app-horizontal-nav-item [item]="item" class="parentBox {{ item.ddType }}">
          </app-horizontal-nav-item>
        }
      </div>
    </div>
  </div>
</div>
}