import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { getAuth, onAuthStateChanged } from 'firebase/auth';

export const authGuard: CanActivateFn = (route, state) => {
  const auth = getAuth(); // Firebase Authentication instance
  const router = inject(Router); // Angular Router injection

  // Use a Promise to wait for Firebase auth state initialization
  return new Promise<boolean>((resolve) => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is authenticated, allow access to the route
        resolve(true);
      } else {
        // User is not authenticated, redirect to the login page
        router.navigate(['/authentication/login']);
        resolve(false); // Block the access to the route
      }
    });
  });
};
