export enum SourceEnum {
    WEB = 1,
    CALL = 2,
    EMAIL = 3,
    WHATSAPP = 4,
    OTHER = 5,
  }
  
  export const SourceText = {
    [SourceEnum.WEB]: 'Web',
    [SourceEnum.CALL]: 'Call',
    [SourceEnum.EMAIL]: 'Email',
    [SourceEnum.WHATSAPP]: 'WhatsApp',
    [SourceEnum.OTHER]: 'Other',
  };
  