<h2 mat-dialog-title>{{ action }}</h2>

<mat-dialog-content *ngIf="action !== 'Delete'">
  <form #deviceForm="ngForm">
    <div class="row">
      <div class="col-sm-12">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Device Name</mat-label>
          <input matInput required [(ngModel)]="local_data.name" name="name" />
        </mat-form-field>
      </div>

      <div class="col-sm-12">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Location Id</mat-label>
          <input matInput required [(ngModel)]="local_data.locationId" name="locationId" />
        </mat-form-field>
      </div>

      <div class="col-sm-12">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Ink Level</mat-label>
          <input matInput required [(ngModel)]="local_data.inkLevel" name="inkLevel" />
        </mat-form-field>
      </div>

      <div class="col-sm-12">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Paper Level</mat-label>
          <input matInput required [(ngModel)]="local_data.paperLevel" name="paperLevel" />
        </mat-form-field>
      </div>

      <div class="col-sm-12">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Error</mat-label>
          <input matInput required [(ngModel)]="local_data.error" name="error" />
        </mat-form-field>
      </div>

      <div class="col-sm-12">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Status</mat-label>
          <select matNativeControl required [(ngModel)]="local_data.status" name="status">
            <option value="idle">Idle</option>
            <option value="use">In Use</option>
          </select>
        </mat-form-field>
      </div>
    </div>
  </form>
</mat-dialog-content>

<mat-dialog-content *ngIf="action === 'Delete'">
  <p>Are you sure you want to delete device <strong>{{ local_data.name }}</strong>?</p>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-flat-button (click)="doAction()" color="primary">{{ action }}</button>
  <button mat-stroked-button (click)="closeDialog()" color="warn">Cancel</button>
</mat-dialog-actions>
