<!-- Listing and total no. of counts -->
<div class="row">
  <div class="col-sm-6 col-lg-3">
    <mat-card class="shadow-none">
      <div class="p-30 bg-light-primary text-center cursor-pointer rounded" (click)="btnCategoryClick('')">
        <h3 class="f-s-24 text-primary f-w-600 m-0 m-b-4">{{ totalCount }}</h3>
        <h6 class="text-primary f-w-600 m-0 f-s-16">Total Tickets</h6>
      </div>
    </mat-card>
  </div>
  <div class="col-sm-6 col-lg-3">
    <mat-card class="shadow-none">
      <div class="p-30 bg-light-warning text-center cursor-pointer rounded" (click)="btnCategoryClick('InProgress')">
        <h3 class="f-s-24 text-warning m-0 m-b-4">{{ Inprogress }}</h3>
        <h6 class="text-warning f-w-600 m-0 f-s-16">In Progress</h6>
      </div>
    </mat-card>
  </div>
  <div class="col-sm-6 col-lg-3">
    <mat-card class="shadow-none">
      <div class="p-30 bg-light-success text-center cursor-pointer rounded" (click)="btnCategoryClick('Open')">
        <h3 class="f-s-24 text-success m-0 m-b-4">{{ Open }}</h3>
        <h6 class="text-success f-w-600 m-0 f-s-16">Open</h6>
      </div>
    </mat-card>
  </div>
  <div class="col-sm-6 col-lg-3">
    <mat-card class="shadow-none">
      <div class="p-30 bg-light-error text-center cursor-pointer rounded" (click)="btnCategoryClick('closed')">
        <h3 class="f-s-24 text-error m-0 m-b-4">{{ Closed }}</h3>
        <h6 class="text-error f-w-600 m-0 f-s-16">Closed</h6>
      </div>
    </mat-card>
  </div>
</div>

<!-- Add and search tickets -->
<mat-card class="cardWithShadow">
  <mat-card-content>
    <div class="row justify-content-start mb-3">
      <div class="col-sm-4">
        <mat-form-field appearance="outline" class="hide-hint">
          <input matInput (keyup)="applyFilterID($any($event.target).value)" placeholder="Search SessionId" />
          <mat-icon matSuffix>
            <i-tabler name="search" class="icon-20"></i-tabler>
          </mat-icon>
        </mat-form-field>
      </div>
    
      <div class="col-sm-4">
        <mat-form-field appearance="outline" class="hide-hint">
          <input matInput (keyup)="applyFilterTicketId($any($event.target).value)" placeholder="Search TicketId" />
          <mat-icon matSuffix>
            <i-tabler name="search" class="icon-20"></i-tabler>
          </mat-icon>
        </mat-form-field>
      </div>
    </div>
    
    <div class="row justify-content-between mb-3">
      <div class="col-sm-4">
        <mat-form-field appearance="outline" class="hide-hint">
          <mat-select (selectionChange)="applyFilterSource($event.value)" placeholder="Select Source">
            <mat-option value="">All Sources</mat-option>
            <mat-option value="email">Email</mat-option>
            <mat-option value="call">Call</mat-option>
            <mat-option value="web">Web</mat-option>
            <mat-option value="whatsapp">WhatsApp</mat-option>
            <mat-option value="other">Other</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    
      <div class="col-sm-4">
        <mat-form-field appearance="outline" class="hide-hint">
          <input matInput [matDatepicker]="picker" (dateChange)="applyFilterDate($event.value)" placeholder="Select Date" />
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      </div>
    
      <div class="col-sm-4 d-flex justify-content-end align-items-center">
        <button mat-flat-button (click)="router.navigate(['/dashboards/createticket'])" color="primary">
          Add Ticket
        </button>
      </div>
    </div>
    
    
    
    
    
  </mat-card-content>
</mat-card>

<!-- Table for displaying tickets -->
<!-- Table for displaying tickets -->
<mat-card class="cardWithShadow">
  <!-- Show spinner while loading data -->
  <div *ngIf="loading" class="loading-container content-center">
    <mat-spinner></mat-spinner>
  </div>

  <mat-paginator 
  [length]="length" 
  [pageSize]="pageSize"
  [pageSizeOptions]="pageSizeOptions"
  [pageIndex]="pageIndex"
  (page)="handlePageEvent($event)"
 
  showFirstLastButtons>
</mat-paginator>
  <!-- Show the table after loading is complete -->
  <mat-card-content>
    <div class="table-responsive">
      <table mat-table [dataSource]="dataSource" matSort class="w-100 f-w-500 no-wrap" *ngIf="!loading">
        
        <!-- Ticket User ID Column -->
        <ng-container matColumnDef="ticketUserId">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="f-s-16 f-w-600">Ticket ID</th>
          <td mat-cell *matCellDef="let element">{{ element.ticketUserId }}</td>
        </ng-container>
  
        <!-- Session ID Column -->
        <ng-container matColumnDef="sessionId">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="f-s-16 f-w-600">Session ID</th>
          <td mat-cell *matCellDef="let element">{{ element.sessionId }}</td>
        </ng-container>
  
        <!-- Name Column -->
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="f-s-16 f-w-600">Name</th>
          <td mat-cell *matCellDef="let element">{{ element.name }}</td>
        </ng-container>
  
        <!-- Category Column -->
        <ng-container matColumnDef="category">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="f-s-16 f-w-600">Category</th>
          <td mat-cell *matCellDef="let element">{{ getCategoryName(element.category) }}</td>
        </ng-container>
  
       
       <!-- Status Column with Chip -->
       <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="f-s-16 f-w-600">Status</th>
        <td mat-cell *matCellDef="let element">
          <mat-chip
            [ngClass]="getChipClass(element.criticality)"
            [ngStyle]="getChipStyle(element.criticality)"
          >
            {{ element.status | statusText }}
          </mat-chip>
        </td>
      </ng-container>
      

  
      
         <!-- Source Column -->
         <ng-container matColumnDef="source">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="f-s-16 f-w-600">Source</th>
          <td mat-cell *matCellDef="let element">{{ getSourceText(element.source) }}</td>
        </ng-container>
  
        <!-- Created At Column -->
        <ng-container matColumnDef="created_at">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="f-s-16 f-w-600">Created Date</th>
          <td mat-cell *matCellDef="let element">{{ element.created_at | date: 'dd.MM.yyyy HH:mm' }}</td>
        </ng-container>
  
        <!-- Last Modified Date Column -->
        <ng-container matColumnDef="lastModifiedDate">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="f-s-16 f-w-600">Last Modified Date</th>
          <td mat-cell *matCellDef="let element">{{ element.lastModifiedDate | date: 'dd.MM.yyyy HH:mm' }}</td>
        </ng-container>
  
        <!-- Action Column -->
        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef class="f-s-16 f-w-600">Action</th>
          <td mat-cell *matCellDef="let element" class="action-link">
            <a (click)="openDialog('Update', element)" class="m-r-10 cursor-pointer">
              <i-tabler name="edit" class="icon-18"></i-tabler>
            </a>
          </td>
        </ng-container>
  
        <!-- Define header and rows -->
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns" class="cursor-pointer" (click)="viewTicketDetails(row)"></tr>

      </table>
    </div>
  </mat-card-content>
  
  
  
  
</mat-card>
