<!-- Add and filter by Access Device ID, Status, and Name Search -->
<mat-card class="cardWithShadow">
  <mat-card-content>
    <div class="row justify-content-between">
      <div class="col-sm-3">
        <!-- Dropdown for Access Device ID -->
        <mat-form-field appearance="outline" class="hide-hint">
          <mat-label>Select Access Device</mat-label>
          <mat-select [(value)]="selectedDeviceId" (selectionChange)="applyFilters()">
            <mat-option *ngFor="let device of devices" [value]="device.deviceId">
              {{ device.deviceId }} - {{ device.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-sm-3">
        <!-- Dropdown for Status -->
        <mat-form-field appearance="outline" class="hide-hint">
          <mat-label>Select Status</mat-label>
          <mat-select [(value)]="selectedStatus" (selectionChange)="applyFilters()">
            <mat-option value="granted">Granted</mat-option>
            <mat-option value="no-access">No Access</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-sm-4">
        <!-- Search field for Name -->
        <mat-form-field appearance="outline" class="hide-hint">
          <mat-label>Search by Name</mat-label>
          <input matInput [(ngModel)]="searchName" (input)="applyFilters()" placeholder="Search Name" />
        </mat-form-field>
      </div>
    </div>
  </mat-card-content>
</mat-card>

<!-- Table to display user data with Access Id, Status, and Actions -->
<mat-card class="cardWithShadow">
  <mat-card-content>
    <div class="table-responsive">
      <table mat-table [dataSource]="dataSource" class="w-100 f-w-500 no-wrap">

        <!-- User ID Column -->
        <ng-container matColumnDef="userId">
          <th mat-header-cell *matHeaderCellDef class="f-s-16 f-w-600">User ID</th>
          <td mat-cell *matCellDef="let element">{{ element.userId }}</td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef class="f-s-16 f-w-600">Name</th>
          <td mat-cell *matCellDef="let element">{{ element.name }}</td>
        </ng-container>

        <!-- Access Device ID Column -->
        <ng-container matColumnDef="accessId">
          <th mat-header-cell *matHeaderCellDef class="f-s-16 f-w-600">Access Device ID</th>
          <td mat-cell *matCellDef="let element">{{ element.accessId }}</td>
        </ng-container>

        <!-- Status Column -->
        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef class="f-s-16 f-w-600">Status</th>
          <td mat-cell *matCellDef="let element">
            <span *ngIf="element.status === 'granted'" class="bg-light-success mat-body-2 f-w-500 p-x-8 p-y-4 f-s-12 rounded-pill">Granted</span>
            <span *ngIf="element.status === 'no-access'" class="bg-light-danger mat-body-2 f-w-500 p-x-8 p-y-4 f-s-12 rounded-pill">No Access</span>
          </td>
        </ng-container>

        <!-- Last Login Column -->
        <ng-container matColumnDef="lastLogin">
          <th mat-header-cell *matHeaderCellDef class="f-s-16 f-w-600">Last Login</th>
          <td mat-cell *matCellDef="let element">{{ element.lastLogin }}</td>
        </ng-container>

        <!-- Actions Column -->
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef class="f-s-16 f-w-600">Actions</th>
          <td mat-cell *matCellDef="let element">
            <button mat-flat-button color="primary" *ngIf="element.status === 'no-access'" (click)="grantAccess(element)">Grant</button>
            <button mat-flat-button color="warn" *ngIf="element.status === 'granted'" (click)="revokeAccess(element)">Revoke</button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </div>

    <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
  </mat-card-content>
</mat-card>
