import { Component } from '@angular/core';
import { TicketmainComponent } from '../../tickets/ticketmain/ticketmain.component';  

@Component({
  selector: 'app-globalticket',
  standalone: true,
  imports: [TicketmainComponent],
  templateUrl: './globalticket.component.html',
  styleUrl: './globalticket.component.scss'
})
export class GlobalticketComponent {

}
