import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { AppSettings, defaults } from '../config';
import { Router } from '@angular/router';
import { getAuth, signOut } from 'firebase/auth';
@Injectable({
  providedIn: 'root',
})
export class CoreService {
  get notify(): Observable<Record<string, any>> {
    return this.notify$.asObservable();
  }

  private auth = getAuth(); // Get Firebase Auth instance

  constructor(private router: Router) {}

  // Method to log out the user
  logout() {
    signOut(this.auth)
      .then(() => {
        // Remove any user data from localStorage (optional)
        localStorage.removeItem('user');

        // Navigate to the login page after logout
        this.router.navigate(['/authentication/login']);
      })
      .catch((error) => {
        console.error('Error logging out:', error);
        alert('An error occurred during logout. Please try again.');
      });
  }
  private notify$ = new BehaviorSubject<Record<string, any>>({});

  getOptions() {
    return this.options;
  }

  setOptions(options: AppSettings) {
    this.options = Object.assign(defaults, options);
    this.notify$.next(this.options);
  }

  private options = defaults;

  getLanguage() {
    return this.options.language;
  }

  setLanguage(lang: string) {
    this.options.language = lang;
    this.notify$.next({ lang });
  }
}
