<h2 mat-dialog-title>{{ action }}</h2>


<mat-dialog-content style="max-width: 500px" class="mat-typography m-t-24">

</mat-dialog-content>

<div *ngIf="action === 'Delete'" class="p-x-24 p-b-24">
  Sure to delete <span class="f-w-600">{{ local_data.name }}</span>?
</div>

<div mat-dialog-actions class="p-24 p-t-0">
  <button mat-flat-button (click)="doAction()" color="primary">
    {{ action }}
  </button>
  <button mat-stroked-button color="warn" (click)="closeDialog()">
    Cancel
  </button>
</div>
