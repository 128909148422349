import { Component, OnInit, Inject, Optional, ViewChild, ChangeDetectorRef } from '@angular/core';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { CommonModule } from '@angular/common';
import { TablerIconsModule } from 'angular-tabler-icons';
import { Router } from '@angular/router';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { TicketService } from 'src/app/services/ticket.service'; // Import TicketService
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from 'src/app/material.module';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { TicketElement } from '../global/globalticket/ticket';
import { User, getAuth } from 'firebase/auth';
import { MatSort } from '@angular/material/sort'; 
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { StatusTextPipe } from './status-text.pipe'; 
import { StatusEnum,StatusText } from './status.enum';
import { SourceEnum, SourceText } from './source.enum';
import { CategoryText } from './category.enum';
// Update the TicketElement interface to reflect all fields from ticketData



@Component({
  selector: 'app-ticket-list',
  templateUrl: './ticketlist.component.html',
  standalone: true,
  
  imports: [ CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,  // Add this
    MatInputModule,      // Add this
    MatButtonModule,     // Add this
    MatDialogModule, 
    MatTableModule,
    MatPaginatorModule,
    StatusTextPipe,
    MaterialModule,
    MatDatepickerModule,
    MatNativeDateModule,
    TablerIconsModule
  ]    // Add this],
})
export class AppTicketlistComponent implements OnInit {

 
  @ViewChild(MatPaginator, {static: false})
  set paginator(value: MatPaginator) {
    if (this.dataSource){
      this.dataSource.paginator = value;
    }
  }// For pagination
  @ViewChild(MatSort, {static: false})
  set sort(value: MatSort) {
    if (this.dataSource){
      this.dataSource.sort = value;
    }
  }
 
  searchText: any;
  totalCount = -1;
  Closed = -1;
  Inprogress = -1;
  Open = -1;
user:User | null;
    // Pagination properties
    length = 0;
    pageSize = 0;
    pageIndex = 0;
    pageSizeOptions = [ 10, 25, 50];
  
    displayedColumns: string[] = [
      'ticketUserId', 'sessionId', 'name', 'category', 'status', 'source', 'created_at', 'lastModifiedDate', 'action'
    ];
    
    
    

  dataSource = new MatTableDataSource<TicketElement>([]); // Specify the TicketElement type

  loading: boolean;
  SourceText = SourceText;
  CategoryText = CategoryText;

  constructor(public dialog: MatDialog, public router: Router, private ticketService: TicketService,private changeDetectorRefs: ChangeDetectorRef) {}
  async ngOnInit(): Promise<void> {
    try {
      await this.ticketService.loadCategories();
      console.log('Categories are ready to use.');
    } catch (error) {
      console.error('Failed to load categories:', error);
    }
    this.loadTickets();
  
    const auth = getAuth();
   this.user = auth.currentUser; 
 
    // Fetch tickets using TicketService
  }

  ngAfterViewInit(): void {
    this.changeDetectorRefs.detectChanges();
    this.dataSource.sort = this.sort;
 this.dataSource.paginator = this.paginator;
  
  }
  
  
  // Fetch all tickets from Firestore using TicketService
  loadTickets(): void {
    this.loading = true; // Set loading to true before fetching data
    this.ticketService.getAllTickets()
      .then(tickets => {
        if (tickets) {
          // Assign the fetched tickets to the dataSource
          this.dataSource = new MatTableDataSource(tickets);

          // Apply initial filter to only show "Open" and "In Progress" tickets
          this.applyDefaultStatusFilter();
        /*   this.btnCategoryClick('Open'); */
          
          this.length = tickets.length;
          // Count tickets based on status
          this.countTicketsByStatus(tickets);
          
          // Render table rows after data is set
          this.loading = false;
        }
      })
      .catch(error => {
        console.error('Error fetching tickets:', error);
      })
      .finally(() => {
        // Set loading to false after data is fetched
      });
  }


  applyDefaultStatusFilter(): void {
    this.dataSource.filterPredicate = (data: TicketElement, filter: string): boolean => {
      // Return true only for "Open" and "In Progress" statuses
      return data.status 
        ? (data.status.toLowerCase() === StatusEnum.OPEN || data.status.toLowerCase() === StatusEnum.IN_PROGRESS) 
        : false;
    };
    
    this.dataSource.filter = 'default'; // Use a placeholder filter value to trigger the filtering
  }
  
  
  
    // Handle pagination events
    handlePageEvent(event: PageEvent) {
      this.pageSize = event.pageSize;
      this.pageIndex = event.pageIndex;
      this.dataSource.paginator = this.paginator;
    }

    
  
  
  // Count tickets based on their status
  countTicketsByStatus(tickets: TicketElement[]): void {
    this.totalCount = tickets.length;
    this.Open = tickets.filter(ticket => !ticket.status || ticket.status.toLowerCase() === StatusEnum.OPEN).length;
    this.Closed = tickets.filter(ticket => ticket.status && ticket.status.toLowerCase() === StatusEnum.CLOSED).length;
    this.Inprogress = tickets.filter(ticket => ticket.status && ticket.status.toLowerCase() === StatusEnum.IN_PROGRESS).length;
  }
  

  getSourceText(source: number): string {
    return SourceText[source as keyof typeof SourceText] || 'Unknown';
  }

  getChipClass(criticality: number | undefined): string {
    if (criticality === 3) {
      return 'green-chip';
    } else if (criticality === 2) {
      return 'yellow-chip';
    } else if (criticality === 1) {
      return 'red-chip';
    } else {
      return ''; // Default class (if no criticality value matches)
    }
  }
  

  getChipStyle(criticality: number | undefined): { [key: string]: string } {
    if (criticality == 3) {
      return { backgroundColor: '#4caf50', color: 'white' }; // Green
    } else if (criticality == 2) {
      return { backgroundColor: '#ffc107', color: 'black' }; // Yellow
    } else if (criticality == 1) {
      return { backgroundColor: '#f44336', color: 'black'  }; // Red
    } else {
      return { backgroundColor: '#e0e0e0', color: 'black' }; // Default (gray)
    }
  }
  


/*   getCategoryText(category: number): string {
    return CategoryText[category as keyof typeof CategoryText] || 'Unknown';
  } */
  
    getCategoryName(categoryId: number, lang: string = 'en'): string {
      console.log(categoryId);
      return this.ticketService.getCategoryText(categoryId, lang);
    }

  applyFilter(filterValue: string): void {
    if (!filterValue) {
      // If no date is selected, reset the filter
      this.applyDefaultStatusFilter();
      return;
    }
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }


  applyFilterID(filterValue: string): void {
    const trimmedFilterValue = filterValue.trim().toLowerCase();

    // If the filter value is empty, reapply the default filter and return
    if (!trimmedFilterValue) {
      this.applyDefaultStatusFilter();
      return;
    }
    // Set a combined filter predicate that checks both the default status and sessionId
    this.dataSource.filterPredicate = (data: TicketElement, filter: string) => {
      // Default filter for status (e.g., Open and In Progress)
      const isStatusMatch = data.status
        ? data.status.toLowerCase() === StatusEnum.OPEN || data.status.toLowerCase() === StatusEnum.IN_PROGRESS
        : false;
  
      // SessionId filter based on the input
      const isSessionIdMatch = data.sessionId
        ? data.sessionId.toLowerCase().includes(filter)
        : false;
  
      // Combine both conditions with an AND operation to apply both filters
      return isStatusMatch && isSessionIdMatch;
    };
  
    // Apply the filter value to trigger the combined filter
    this.dataSource.filter = trimmedFilterValue;
  }
  

  applyFilterSource(selectedSource: string): void {
    const trimmedSource = selectedSource ? selectedSource.toLowerCase() : '';
  
    // If no source is selected, reapply only the default filter and exit
    if (!trimmedSource) {
      this.applyDefaultStatusFilter();
      return;
    }
  
    // Set a combined filter predicate that includes both the default status filter and the source filter
    this.dataSource.filterPredicate = (data: TicketElement, filter: string) => {
      // Default filter for status (e.g., Open and In Progress)
      const isStatusMatch = data.status
      ? data.status.toLowerCase() === StatusEnum.OPEN || data.status.toLowerCase() === StatusEnum.IN_PROGRESS
        : false;
  
      // Source filter based on the selected value
      const isSourceMatch = data.source
      ? SourceText[data.source as keyof typeof SourceText].toLowerCase() === filter
      : false;
    
  
      // Combine both conditions with an AND operation
      return isStatusMatch && isSourceMatch;
    };
  
    // Apply the selected source as the filter
    this.dataSource.filter = trimmedSource;
  }
  

  applyFilterDate(selectedDate: Date | null): void {
    // If no date is selected, reapply only the default filter and exit
    if (!selectedDate) {
      this.applyDefaultStatusFilter();
      return;
    }
  
    // Convert the selected date to 'MM/dd/yyyy' format for comparison
    const selectedDateFormatted = selectedDate.toLocaleDateString('en-US');
  
    // Set a combined filter predicate that includes both the default status filter and the date filter
    this.dataSource.filterPredicate = (data: TicketElement, filter: string) => {
      // Default filter for status (e.g., Open and In Progress)
      const isStatusMatch = data.status
      ? data.status.toLowerCase() === StatusEnum.OPEN || data.status.toLowerCase() === StatusEnum.IN_PROGRESS
        : false;
  
      // Date filter, comparing data.date to the selected date in 'MM/dd/yyyy' format
      const dataDateFormatted = new Date(data.date).toLocaleDateString('en-US');
      const isDateMatch = data.date ? dataDateFormatted === filter : false;
  
      // Combine both conditions with an AND operation
      return isStatusMatch && isDateMatch;
    };
  
    // Apply the formatted selected date as the filter
    this.dataSource.filter = selectedDateFormatted;
  }
  

  
  applyFilterTicketId(selectedName: string): void {
    const trimmedName = selectedName.trim().toLowerCase();
  
    // If no name is entered, reapply only the default filter and exit
    if (!trimmedName) {
      this.applyDefaultStatusFilter();
      return;
    }
  
    // Set a combined filter predicate that includes both the default status filter and the name filter
    this.dataSource.filterPredicate = (data: TicketElement, filter: string) => {
      // Default filter for status (e.g., Open and In Progress)
      const isStatusMatch = data.status
        ? data.status.toLowerCase() === StatusEnum.OPEN || data.status.toLowerCase() === StatusEnum.IN_PROGRESS
        : false;
  
      // Convert ticketUserId to a string for comparison and apply the name filter
      const isNameMatch = data.ticketUserId
        ? data.ticketUserId.toString().toLowerCase().includes(filter)
        : false;
  
      // Combine both conditions with an AND operation
      return isStatusMatch && isNameMatch;
    };
  
    // Apply the selected name as the filter to trigger the combined filter
    this.dataSource.filter = trimmedName;
  }
  
  
  

  btnCategoryClick(val: string): void {
    if (val === 'Open') {
      // Show only "Open" tickets
      this.dataSource.filterPredicate = (data: TicketElement, filter: string): boolean => {
        return data.status ? data.status.toLowerCase() === StatusEnum.OPEN : false;
      };
      this.dataSource.filter = StatusEnum.OPEN; // Trigger the filter for "Open"
    } else if (val === 'InProgress') {
      // Show only "In Progress" tickets
      this.dataSource.filterPredicate = (data: TicketElement, filter: string): boolean => {
        return data.status ? data.status.toLowerCase() ===  StatusEnum.IN_PROGRESS : false;
      };
      this.dataSource.filter = StatusEnum.IN_PROGRESS; // Trigger the filter for "In Progress"
    } else if (val === 'closed') {
      // Show only "Closed" tickets
      this.dataSource.filterPredicate = (data: TicketElement, filter: string): boolean => {
        return data.status ? data.status.toLowerCase() === StatusEnum.CLOSED : false;
      };
      this.dataSource.filter = StatusEnum.CLOSED; // Trigger the filter for "Closed"
    } else {
      // Apply the default filter for "Open" and "In Progress" tickets
      this.applyDefaultStatusFilter();
    }
  }
  
  

  viewTicketDetails(ticket: any): void {
    this.router.navigate(['/dashboards/ticketsview'], {
      queryParams: {
        ticketId: ticket.ticketUserId,
        fileId: ticket.fileId || null
      }
    });
  }
  
  openDialog(action: string, obj: TicketElement): void {
    obj.action = action;
    const dialogRef = this.dialog.open(AppTicketDialogContentComponent, {
      data: obj,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result.event === 'Update') {
        const now = new Date();
        const formattedDate = now.getFullYear() + '-' +
                              ('0' + (now.getMonth() + 1)).slice(-2) + '-' +
                              ('0' + now.getDate()).slice(-2) + ' ' +
                              ('0' + now.getHours()).slice(-2) + ':' +
                              ('0' + now.getMinutes()).slice(-2) + ':' +
                              ('0' + now.getSeconds()).slice(-2);
        result.data.lastmodifiedDate =formattedDate;
        result.data.lastmodifiedUser = this.user?.uid;
        this.updateTicket(result.data);
      }
    });
  }

  // Update a ticket using TicketService
  async updateTicket(ticket: TicketElement): Promise<void> {
    try {
      // Update the ticket in the database
      await this.ticketService.updateTicketById(ticket.id, ticket);
  
      // Update the ticket in the dataSource without losing type information
      this.dataSource.data = this.dataSource.data.map((t: TicketElement) => 
        t.id === ticket.id ? { ...t, ...ticket } : t
      );
  
      // Re-render the rows to reflect the update
      this.loadTickets();
  
      console.log('Ticket updated successfully.');
    } catch (error) {
      console.error('Error updating ticket:', error);
    }
  }
  
}


@Component({
  selector: 'app-dialog-content',
  templateUrl: 'ticket-dialog-content.html',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatDialogModule,
  ],
})
export class AppTicketDialogContentComponent {
  action: string;
  local_data: any;
  formattedCreatedDate: string | null = null;
  formattedLastModifiedDate: string | null = null;

  // Make StatusEnum and StatusText available in the template
  StatusEnum = StatusEnum;
  StatusText = StatusText;
  SourceEnum = SourceEnum;
  SourceText = SourceText;
  CategoryText = CategoryText;
  categoryKeys = Object.keys(CategoryText).map(key => Number(key) as keyof typeof CategoryText);

  constructor(
    public dialogRef: MatDialogRef<AppTicketDialogContentComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: TicketElement
  ) {
    this.local_data = { ...data };
    this.action = this.local_data.action;
    this.initializeFormattedDates();
    this.initializeDropdownValues();
  }

  initializeFormattedDates(): void {
    if (this.local_data.date) {
      this.formattedCreatedDate = this.formatToDatetimeLocal(this.local_data.date);
    }

    if (this.local_data.lastmodifiedDate) {
      this.formattedLastModifiedDate = this.formatToDatetimeLocal(this.local_data.lastmodifiedDate);
    }
  }

  formatToDatetimeLocal(dateString: string): string {
    const date = new Date(dateString);
    return date.toISOString().slice(0, 16); // 'yyyy-MM-ddTHH:mm'
  }


  isImage(fileUrl?: string): boolean {
    if (!fileUrl) {
      return false; // or handle the case where fileUrl is undefined as needed
    }
  
    const imageExtensions = ['jpg', 'jpeg', 'png', 'gif'];
    const extension = fileUrl.split('.').pop()?.toLowerCase(); // Use optional chaining
    return extension ? imageExtensions.includes(extension) : false; // Check if extension is valid
  }
  
  
  openInNewTab(fileUrl: string): void {
    window.open(fileUrl, '_blank');
  }

  
  initializeDropdownValues(): void {
    if (!this.local_data.status) {
      this.local_data.status = StatusEnum.OPEN;
    }
    this.local_data.statusText = StatusText[this.local_data.status as StatusEnum];

    if (this.local_data.source != null) {
      this.local_data.sourceText = SourceText[this.local_data.source as SourceEnum];
    }
    if (this.local_data.category != null) {
      this.local_data.categoryText = CategoryText[this.local_data.category as keyof typeof CategoryText];
    }
    
  }
  doAction(): void {
    this.local_data.date = this.formattedCreatedDate ? new Date(this.formattedCreatedDate).toISOString() : null;
    this.local_data.lastmodifiedDate = this.formattedLastModifiedDate ? new Date(this.formattedLastModifiedDate).toISOString() : null;
    this.dialogRef.close({ event: this.action, data: this.local_data });
  }

  closeDialog(): void {
    this.dialogRef.close({ event: 'Cancel' });
  }
}
