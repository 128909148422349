import { Injectable } from '@angular/core';
import { getFirestore, doc, updateDoc, getDocs, collection, query } from 'firebase/firestore';
import { initializeApp } from 'firebase/app';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TicketService {

  private firebaseApp = initializeApp(environment.firebaseConfig); // Firebase initialization
  private db = getFirestore(this.firebaseApp); // Firestore instance
  public categoryMap: Record<number, Record<string, string>> = {};
  private defaultLang = 'en';
  ticket: any = null;
  fileMetadata: any = null;


  private baseUrl = environment.environmentWeb.baseUrl; // Base URL from environment
  private apiKey = environment.environmentWeb.secretKey;  // API key from environment

  constructor() {}

/*   // 1. Get all tickets from Firestore
  async getAllTickets(): Promise<any[]> {
    try {
      const ticketCollection = collection(this.db, 'tickets');
      const ticketSnapshot = await getDocs(ticketCollection);
      const tickets = ticketSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      console.log('ti');
      console.log(tickets);
      return tickets;
    } catch (error) {
      console.error('Error fetching tickets:', error);
      throw new Error('Unable to fetch tickets.');
    }
  }
 */

  async getAllTickets(): Promise<any[]> {
    try {
        // Define the API endpoint and headers
      
        const apiUrl =  `${environment.environmentWeb.baseUrl}/tickets/tickets`;
        const headers = {
            'x-api-key': environment.environmentWeb.secretKey,
            'Content-Type': 'application/json'
        };

        // Make the HTTP GET request
        const response = await fetch(apiUrl, {
            method: 'GET',
            headers
        });

        // Check if the response is successful
        if (!response.ok) {
            throw new Error(`Failed to fetch tickets. Status: ${response.status}`);
        }

        // Parse the response as JSON
        const tickets = await response.json();

        console.log('Fetched tickets:', tickets);
        return tickets;
    } catch (error) {
        console.error('Error fetching tickets:', error);
        throw new Error('Unable to fetch tickets.');
    }
}


  // 2. Update a ticket by its ID
  async updateTicketById(ticketId: string, updatedData: any): Promise<void> {
    try {
      const ticketDocRef = doc(this.db, 'tickets', ticketId);
      await updateDoc(ticketDocRef, updatedData);
      console.log('Ticket updated successfully:', ticketId);
    } catch (error) {
      console.error('Error updating ticket:', error);
      throw new Error('Unable to update the ticket.');
    }
  }

  // 3. Update only the status of a ticket
  async updateTicketStatus(ticketId: string, newStatus: string): Promise<void> {
    try {
      const ticketDocRef = doc(this.db, 'tickets', ticketId);
      await updateDoc(ticketDocRef, { status: newStatus });
      console.log('Ticket status updated successfully:', ticketId);
    } catch (error) {
      console.error('Error updating ticket status:', error);
      throw new Error('Unable to update ticket status.');
    }
  }



  async loadCategories(): Promise<void> {
    try {
      const url = `${environment.environmentWeb.baseUrl}/topics/all/`;
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': environment.environmentWeb.secretKey
        }
      });

      if (!response.ok) {
        throw new Error(`Failed to fetch categories. Status: ${response.status}`);
      }

      const categories = await response.json();

      // Populate categoryMap with category descriptions keyed by ID and language
      categories.forEach((category: { id: number; lang: string; description: string }) => {
        if (!this.categoryMap[category.id]) {
          this.categoryMap[category.id] = {};
        }
        this.categoryMap[category.id][category.lang] = category.description;
      });

      console.log('Categories loaded:', this.categoryMap);
    } catch (error) {
      console.error('Error loading categories:', error);
      throw new Error('Unable to load category descriptions.');
    }
  }


  getCategoryText(categoryId: number, lang: string = this.defaultLang): string {
    console.log(this.categoryMap[categoryId]?.[this.defaultLang])
    return (
      this.categoryMap[categoryId]?.[lang] || 
      this.categoryMap[categoryId]?.[this.defaultLang] || 
      'Unknown'
    );
  }




  async loadTicketDetails(ticketId: string): Promise<any> {
    const url = `${environment.environmentWeb.baseUrl}/tickets/ticket/${ticketId}`;
    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': environment.environmentWeb.secretKey,
        },
      });
  
      if (!response.ok) {
        throw new Error(`Failed to fetch ticket details. Status: ${response.status}`);
      }
  
      const ticket = await response.json();
      console.log('Fetched ticket details:', ticket);
      return ticket;
    } catch (error) {
      console.error('Error fetching ticket details:', error);
      throw error;
    }
  }
  
  async loadFileDetails(fileId: string): Promise<any> {
    const url = `${environment.environmentWeb.baseUrl}/upload/file/${fileId}`;
    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': environment.environmentWeb.secretKey,
        },
      });
  
      if (!response.ok) {
        throw new Error(`Failed to fetch file details. Status: ${response.status}`);
      }
  
      const result = await response.json();
      console.log('Fetched file details:', result.fileMetadata);
      return result.fileMetadata;
    } catch (error) {
      console.error('Error fetching file details:', error);
      throw error;
    }
  }
  


  async createTicketNote(noteData: any): Promise<any> {
    const url = `${environment.environmentWeb.baseUrl}/ticketNotes/create`;
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': environment.environmentWeb.secretKey,
        },
        body: JSON.stringify(noteData),
      });
  
      if (!response.ok) {
        throw new Error(`Failed to create ticket note. Status: ${response.status}`);
      }
  
      return await response.json();
    } catch (error) {
      console.error('Error creating ticket note:', error);
      throw error;
    }
  }
  
  async getTicketNotesByTicketId(ticketId: number): Promise<any[]> {
    const url = `${environment.environmentWeb.baseUrl}/ticketNotes/ticket/${ticketId}`;
    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': environment.environmentWeb.secretKey,
        },
      });
  
      if (!response.ok) {
        throw new Error(`Failed to fetch ticket notes. Status: ${response.status}`);
      }
  
      const notes = await response.json();
  
      // Sort the notes in reverse chronological order by `created_at`
      return notes.sort((a: any, b: any) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime());
    } catch (error) {
      console.error('Error fetching ticket notes:', error);
      throw error;
    }
  }

  async updateTicket(ticketId: string, updatedData: any): Promise<any> {
    const url = `${environment.environmentWeb.baseUrl}/tickets/ticket/${ticketId}`;
    try {
      const response = await fetch(url, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': environment.environmentWeb.secretKey,
        },
        body: JSON.stringify(updatedData),
      });
  
      if (!response.ok) {
        throw new Error(`Failed to update ticket. Status: ${response.status}`);
      }
  
      const result = await response.json();
      console.log('Ticket updated successfully:', result);
      return result;
    } catch (error) {
      console.error('Error updating ticket:', error);
      throw error;
    }
  }
  
  
  

  async createNote(
    ticketId: number,
    note: string,
    action: string,
    currentUser: string
  ): Promise<any> {
    const url = `${environment.environmentWeb.baseUrl}/ticketNotes/create`;
  
    const noteData = {
      ticket_id: ticketId,
      action: action,
      note: note,
      created_by: currentUser,
    };
  
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': environment.environmentWeb.secretKey,
        },
        body: JSON.stringify(noteData),
      });
  
      if (!response.ok) {
        throw new Error(`Failed to create note. Status: ${response.status}`);
      }
  
      const result = await response.json();
      console.log('Note created successfully:', result);
      return result;
    } catch (error) {
      console.error('Error creating note:', error);
      throw error;
    }
  }
  

  // Method to upload a file and get file metadata
  async uploadFile(file: File): Promise<any> {
    const formData = new FormData();
    formData.append('file', file);

    try {
      const response = await fetch(`${this.baseUrl}/upload/uploadfile`, {
        method: 'POST',
        headers: {
          'x-api-key': this.apiKey
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error(`Failed to upload file. Status: ${response.status}`);
      }

      const result = await response.json();
      console.log('File uploaded successfully:', result);
      return result;
    } catch (error) {
      console.error('Error uploading file:', error);
      throw error;
    }
  }

  // Method to create a ticket
  async createTicket(ticketData: any): Promise<any> {
    try {

       console.log(ticketData);
      const response = await fetch(`${this.baseUrl}/tickets/createticket`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': this.apiKey
        },
        body: JSON.stringify(ticketData),
      });

      if (!response.ok) {
        throw new Error(`Failed to create ticket. Status: ${response.status}`);
      }

      const result = await response.json();
      console.log('Ticket created successfully:', result);
      return result;
    } catch (error) {
      console.error('Error creating ticket:', error);
      throw error;
    }
  }

  // Wrapper method to handle file upload and ticket creation sequentially
  async createTicketWithFile(file: File, ticketData: any): Promise<any> {
    try {
      // Step 1: Upload file and get fileId
      const uploadResponse = await this.uploadFile(file);
      const fileId = uploadResponse.fileId;

      // Step 2: Include fileId in ticketData and create the ticket
      const ticketDataWithFile = { ...ticketData, fileId };
      const ticketResponse = await this.createTicket(ticketDataWithFile);

      return ticketResponse;
    } catch (error) {
      console.error('Error in ticket creation process:', error);
      throw error;
    }
  }




}







